<template>
  <v-fab-transition>
    <v-speed-dial
      v-model="dialed"
      top
      absolute
      small
      direction="bottom"
      transition="slide-y-transition"
      right>
      <template v-slot:activator>
        <v-btn v-model="dialed" dark fab color="pink">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-btn fab dark color="orange" @click="addTask" small>
        <v-icon>mdi-check</v-icon>
      </v-btn>
      <v-btn fab dark color="blue" @click="addEvent" small>
        <v-icon>mdi-calendar-plus</v-icon>
      </v-btn>
      <v-btn fab dark color="green" @click="addContact" small>
        <v-icon>mdi-account-plus</v-icon>
      </v-btn>
      <v-btn fab dark color="purple" @click="addFile" small>
        <v-icon>mdi-file-plus</v-icon>
      </v-btn>
    </v-speed-dial>
  </v-fab-transition>
</template>

<script>
export default {
  name: 'NewItemIcon',
  data: () => ({
    dialed: false
  }),
  methods: {
    addTask () {
      this.$emit('addTaskRequested')
    },
    addEvent () {
      this.$emit('addEventRequested')
    },
    addContact () {
      this.$emit('addContactRequested')
    },
    addFile () {
      this.$emit('addFileRequested')
    }
  }
}
</script>

<style scoped>

</style>
